import React from 'react'
import img1 from '../../assests/homebtm/mini_logo1.webp'
import img2 from '../../assests/homebtm/mini_logo2.webp'
import img3 from '../../assests/homebtm/mini_logo3.webp'
import img4 from '../../assests/homebtm/mini_logo4.webp'
import img5 from '../../assests/homebtm/mini_logo5.webp'
import img6 from '../../assests/homebtm/mini_logo6.webp'
import img7 from '../../assests/homebtm/mini_logo7.webp'
import img8 from '../../assests/homebtm/mini_logo8.webp'
import HomeBtnChild from './HomeBtmChild'


const AppHomeBtm = () => {
  const images = [
    { src: img1, alt: 'Image 1', ImgWidth:"115px" },
    { src: img2, alt: 'Image 2', ImgWidth:"86px" },
    { src: img3, alt: 'Image 3', ImgWidth:" 104px" },
    { src: img4, alt: 'Image 4', ImgWidth:" 72px" },
    { src: img5, alt: 'Image 5', ImgWidth:" 109px" },
    { src: img6, alt: 'Image 6', ImgWidth:" 72px" },
    { src: img7, alt: 'Image 7', ImgWidth:" 64px" },
    { src: img8, alt: 'Image 8', ImgWidth:"126px" }
  ];

  return (
    <div>
      <HomeBtnChild images={images} />
    </div>
  );
}

export default AppHomeBtm
