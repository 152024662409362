
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppHome from './components/Home/AppHome';
import AppHomeBtm from './components/Home_Bottom_Sec/AppHomeBtm';
import S2development from './components/S2_Development/S2development';
import S3OfferSolutions from './components/S4_Offer_Sol/S3OfferSolutions';
import S4Products from './components/Product_Sec/S4Products';
import ClientReviewsSlider from './components/ClientSlider/ClientReviewsSlider';
import TechStacksParent from './components/TechStacks/TechStacksParent';
import WeAreSigi from './components/About_Us/WeAreSigi';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';



function App() {
  return (
    <>

      <AppHome />
      <AppHomeBtm />
      <S2development />
      <S3OfferSolutions />
      <S4Products />
      <ClientReviewsSlider />
      <TechStacksParent/>
      <WeAreSigi/>
      <ContactUs/>
      <Footer/>
       
    </>
  );
}


export default App;
