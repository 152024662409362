import React from "react";
import "../ContactUs/ContactUs.css";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import db from "../../firebase-cofig.js";

const ContactUs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();
  const collectionRef = collection(db, "SBbaseQueryTable");

  const onSubmit = async (data) => {
    try {
      await addDoc(collectionRef, {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        referral: "",
        budget: data.budget,
        message: data.message,
      });
      toast("Thanks for reaching out to us!🙂");
      reset();
    } catch (error) {
      console.error("Error during form submission:", error);
      toast("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <ToastContainer />
      <section id="ContactUs" className="Contact-Us">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-75 contactUs-outer mx-auto my-5 p-4 rounded"
        >
          <div className="form-outer w-75 mx-auto py-3">
            <h1 className="fw-bolder text-center">Let's Talk</h1>
            <p className="text-center">
              We've worked with clients of all sizes, from enterprise brands to
              funded startups. Let's talk about your project and how we can help
              provide value.
            </p>

            <div className="row">
              <div className="col-6 my-2 my-sm-3">
                <label htmlFor="inputFirstName" className="form-label">
                  First Name
                </label>
                <input
                  type="text"
                  {...register("firstName", {
                    required: "First name is required.",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "First name must not contain numbers.",
                    },
                    minLength: { value: 3, message: "Minimum length is 3." },
                    maxLength: { value: 12, message: "Maximum length is 12." },
                  })}
                  className="form-control"
                  id="inputFirstName"
                />
                {errors.firstName && (
                  <p className="pt-2 text-danger m-0">{errors.firstName.message}</p>
                )}
              </div>
              <div className="col-6 my-2 my-sm-3">
                <label htmlFor="inputLastName" className="form-label">
                  Last Name
                </label>
                <input
                  type="text"
                  {...register("lastName", {
                    required: "Last name is required.",
                    pattern: {
                      value: /^[a-zA-Z\s]+$/,
                      message: "Last name must not contain numbers.",
                    },
                    minLength: { value: 3, message: "Minimum length is 3." },
                    maxLength: { value: 16, message: "Maximum length is 16." },
                  })}
                  className="form-control"
                  id="inputLastName"
                />
                {errors.lastName && (
                  <p className="pt-2 text-danger">{errors.lastName.message}</p>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-6 my-2  my-sm-2">
                <label htmlFor="inputEmail" className="form-label">
                  Email
                </label>
                <input
                  type="text"
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Please enter a valid email address.",
                    },
                  })}
                  className="form-control"
                  id="inputEmail"
                />
                {errors.email && (
                  <p className="pt-2 text-danger m-0">{errors.email.message}</p>
                )}
              </div>
              <div className="col-6 my-2 my-sm-2">
                <label htmlFor="inputBudget" className="form-label">
                  Budget
                </label>
                <input
                  type="text"
                  {...register("budget", {
                    pattern: {
                      value: /^[0-9]{1,10}$/,
                      message:
                        "Budget must be a number and not exceed 10 digits.",
                    },
                  })}
                  className="form-control"
                  id="inputBudget"
                />
                {errors.budget && (
                  <p className="pt-2 text-danger m-0">{errors.budget.message}</p>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 my-2 my-sm-3">
                <label htmlFor="inputMessage" className="form-label">
                  Message
                </label>
                <textarea
                  {...register("message", {
                    required: "Message is required.",
                    maxLength: {
                      value: 850,
                      message: "Message can't exceed 850 characters.",
                    },
                  })}
                  className="form-control"
                  id="inputMessage"
                  rows="4"
                  style={{ resize: "none" }}
                ></textarea>
                {errors.message && (
                  <p className="pt-2 text-danger m-0">{errors.message.message}</p>
                )}
              </div>
            </div>

            <div className="text-sm-center text-md-end mt-3">
              <button type="submit" className="btn btn-primary px-5 pt-2">
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default ContactUs;
