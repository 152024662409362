import React, { useState, useEffect } from "react";
import "./home.css";
import AppNavbar from "../Navbar/AppNavbar";
import { Container, Row, Col, Button } from "react-bootstrap";
import img1 from "../../assests/home/icons1.png";
import img2 from "../../assests/home/icons2.png";
import gpt from "../../assests/home/icon-gpt.webp";
import claud from "../../assests/home/icon-claud.webp";
import gemini from "../../assests/home/icon-gemini.webp";
import plexity from "../../assests/home/icon-plexity.webp";

const AppHome = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const images = [img1, img2];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevIndex) => (prevIndex + 1) % images.length); // Loop through images
    }, 4000); // Change image every 4 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [images.length]);

  return (
    <>
      <Container fluid id="AppHome" className="home-parent">
        <AppNavbar />
        <Container className="content-outer text-white">
          <Row>
            <Col lg="6" className="py-5">
              <h1 className="py-3 m-0 fw-bolder">
                Software development and AI powered solutions for your digital
                success
              </h1>
              <p className="pt-4 mb-0">
                We transform enterprises, mid-sized businesses and startups with
                powerful and adaptable digital solutions that satisfy their
                contemporary needs and unlock future opportunities for them.
              </p>
              <p className="pt-3 fw-bolder">
                We put AI to help you build the future of your business using:
              </p>
              <div className="home-btns-outer mt-3">
                <div className="box">
                  <img className="gpt" src={gpt} alt="" />
                </div>
                <div className="box">
                  <img className="claud" src={claud} alt="" />
                </div>
                <div className="box">
                  <img className="gemini" src={gemini} alt="" />
                </div>
                <div className="box">
                  <img className="plexity" src={plexity} alt="" />
                </div>
              </div>

              {/* <div div className="d-flex justify-content-start gap-3 pt-5">
                <Button variant="primary" href="#signup" className="home-btns">
                  Estimate Project
                </Button>
                <Button
                  variant="primary"
                  href="#signup"
                  className="home-btns home-studies-btn"
                >
                  Case Studies
                </Button>
              </div> */}
            </Col>
            <Col lg="6">
              <img
                className={`icons home-icon img-fluid fade-in-out`}
                src={images[currentImage]}
                alt="Dynamic Icon"
              />
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};

export default AppHome;
