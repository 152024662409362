
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
//MARK- don't remove thes lines
import { getAppCheck, initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";


const firebaseConfig = {
  apiKey: "AIzaSyDC8JkzUFJKfE5Rn6bMVD279CLrqsackXY",
  authDomain: "siredbits-c52aa.firebaseapp.com",
  projectId: "siredbits-c52aa",
  storageBucket: "siredbits-c52aa.appspot.com",
  messagingSenderId: "389798349383",
  appId: "1:389798349383:web:7cb715dd2751d210feff62",
  measurementId: "G-HF6R1TS5R8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Firestore
const db = getFirestore(app);

// Initialize App Check with reCAPTCHA
// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider("6LcF5akqAAAAAJX81tUFtROSsQQZRHLm958vuhgK"), //your reCAPTCHA site key
//   isTokenAutoRefreshEnabled: true, // Enables token auto-refresh
// });

export default db;



