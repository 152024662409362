import React from 'react';
import { Col, Row } from 'react-bootstrap';

const ProductCards = ({ logo, heading, para, productImg, bgImage, bgColor, ImgWidth, logoWidth }) => (
  <Col
    md={12}
    className="my-4 p-5"
    style={{
      backgroundImage: `url(${bgImage})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: bgColor || '',
    }}
  >
    <Row className="d-flex justify-content-center gap-lg-0">
      <Col md={12} lg={6} className="text-white d-flex flex-column justify-content-center ps-lg-5">
        <img src={logo} alt="logo" className="pb-5" style={{ width: logoWidth }} />
        <h1 className="pb-3">{heading}</h1>
        <p className="product-after-bar">{para}</p>
      </Col>
      <Col md={6} className="product-card-img-outer my-5 my-lg-0">
        <img src={productImg} className="img-fluid" alt="product" style={{ width: ImgWidth }} />
      </Col>
    </Row>
  </Col>
);

export default ProductCards;
