import React from 'react'
import Card from 'react-bootstrap/Card';

const S3Cards = ({ image, title, description }) => {
    return (
        <Card className='py-3' >
            <Card.Body className="d-flex flex-column flex-md-row align-items-center text-dark text-center text-md-start gap-4 gap-lg-0">
                <Card.Img variant="top" src={image} style={{ height: '50px', width:'50px', cursor: 'pointer', marginRight:'20px' }} />
                <div className="title-para-wrapper">
                <Card.Title style={{"fontWeight":"bolder"}}>{title}</Card.Title>
                    <Card.Text>
                        {description}
                    </Card.Text>
                </div>
            </Card.Body>
        </Card>
    )
}

export default S3Cards
