import React from "react";
import "./TechStacks.css";


// Technologies
import icon_flutter from "../../assests/techstacks/technologies/flutter.png";
import icon_ios from "../../assests/techstacks/technologies/ios.png";
import icon_andriod from "../../assests/techstacks/technologies/andriod.png";
import icon_firebase from "../../assests/techstacks/technologies/firebase.png";
import icon_jira from "../../assests/techstacks/technologies/jira.png";
import icon_sql from "../../assests/techstacks/technologies/sql.png";
import icon_wordpress from "../../assests/techstacks/technologies/wordpress.png";
import icon_shopify from "../../assests/techstacks/technologies/shopify.png";
import icon_react from "../../assests/techstacks/technologies/react.png";
import icon_next from "../../assests/techstacks/technologies/next.png";
import icon_node from "../../assests/techstacks/technologies/node.png";
import icon_angular from "../../assests/techstacks/technologies/angular.png";

// FrameWorks

import icon_go from "../../assests/techstacks/frameworks/go.png";
// import django from "../../assests/techstacks/FrameWorks/django.png";
import dotNET from "../../assests/techstacks/frameworks/dotnet.png";
import express from "../../assests/techstacks/frameworks/express.png";
import swift from "../../assests/techstacks/frameworks/ios-swift.png";
import java from "../../assests/techstacks/frameworks/java.png";
import laravel from "../../assests/techstacks/frameworks/laravel.png";
import mongoDB from "../../assests/techstacks/frameworks/mongodb.png";
import python from "../../assests/techstacks/frameworks/python.png";
import Native from "../../assests/techstacks/frameworks/react-native.png";
import ruby from "../../assests/techstacks/frameworks/ruby.png";
import vue from "../../assests/techstacks/frameworks/vue.png";
import wix from "../../assests/techstacks/frameworks/wix.png";

// BusinessAreas

import icon_home_services from "../../assests/techstacks/businessareas/home-services.png";
import icon_beauty from "../../assests/techstacks/businessareas/beauty.png";
import icon_ecommerce from "../../assests/techstacks/businessareas/ecommerce.png";
import icon_education from "../../assests/techstacks/businessareas/eeducation.png";
import icon_fintech from "../../assests/techstacks/businessareas/fintech.png";
import icon_food from "../../assests/techstacks/businessareas/food.png";
import icon_healthcare from "../../assests/techstacks/businessareas/healthcare.png";
import icon_industry from "../../assests/techstacks/businessareas/industry.png";
import icon_nft from "../../assests/techstacks/businessareas/nft.png";
import icon_robotics from "../../assests/techstacks/businessareas/robotics.png";
import icon_tour from "../../assests/techstacks/businessareas/tour.png";
import icon_transport from "../../assests/techstacks/businessareas/transport.png";

// Our Experts

import icon_aws from "../../assests/techstacks/ourexperts/aws.png";
import icon_figma from "../../assests/techstacks/ourexperts/figma.png";
import icon_seo from "../../assests/techstacks/ourexperts/seo.png";
import icon_slack from "../../assests/techstacks/ourexperts/slack.png";
import icon_design from "../../assests/techstacks/ourexperts/design.png";
import icon_project_manager from "../../assests/techstacks/ourexperts/project-manager.png";
import icon_data_engineer from "../../assests/techstacks/ourexperts/data-engineer.png";
import icon_analyst from "../../assests/techstacks/ourexperts/analysit.png";
import icon_github from "../../assests/techstacks/ourexperts/github.png";
import icon_sqa from "../../assests/techstacks/ourexperts/sqa.png";
import icon_open_apis from "../../assests/techstacks/ourexperts/open-apis.png";
import icon_docker from "../../assests/techstacks/ourexperts/docker.png";

const TechStacksChild = ({ selectedCategory }) => {
  const StackData = {
    technologies: [
      { img: icon_flutter },
      { img: icon_ios },
      { img: icon_andriod },
      { img: icon_firebase },
      { img: icon_jira },
      { img: icon_sql },
      { img: icon_wordpress },
      { img: icon_shopify },
      { img: icon_react },
      { img: icon_next },
      { img: icon_node },
      { img: icon_angular },
    ],
    frameworks: [
      { img: icon_go },
      { img: dotNET },
      { img: express },
      { img: swift },
      { img: java },
      { img: laravel },
      { img: ruby },
      { img: mongoDB },
      { img: python },
      { img: Native },
      { img: vue },
      { img: wix },
    ],
    techAreas: [
      { img: icon_home_services },
      { img: icon_beauty },
      { img: icon_ecommerce },
      { img: icon_education },
      { img: icon_fintech },
      { img: icon_food },
      { img: icon_healthcare },
      { img: icon_industry },
      { img: icon_nft },
      { img: icon_robotics },
      { img: icon_tour },
      { img: icon_transport },
    ],
    
    ourExperts: [
      { img: icon_aws },
      { img: icon_figma },
      { img: icon_seo },
      { img: icon_slack },
      { img: icon_design },
      { img: icon_project_manager },
      { img: icon_data_engineer },
      { img: icon_analyst },
      { img: icon_github },
      { img: icon_sqa },
      { img: icon_open_apis },
      { img: icon_docker },
    ],
    
  };
  

  const categoryContent = StackData[selectedCategory] || [];

  return (
    <div>
      <div className="techStackGrid">
        {categoryContent.map((item, index) => (
          <div key={index} className="techBox">
            <img src={item.img} alt={item.name} className="techImage" />
            {/* <p className="techName text-white">{item.name}</p> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechStacksChild;
