import React from 'react'
import { Container, Row, Col} from 'react-bootstrap'
import S3Cards from './S3OfferCards'
import img1 from '../../assests/offer_solutions/sec3-logo1.svg'
import img2 from '../../assests/offer_solutions/sec3-logo2.svg'
import img3 from '../../assests/offer_solutions/sec3-logo3.svg'
import img4 from '../../assests/offer_solutions/sec3-logo4.svg'
import img5 from '../../assests/offer_solutions/sec3-logo5.svg'
import img6 from '../../assests/offer_solutions/sec3-logo6.svg'
import img7 from '../../assests/offer_solutions/sec3-logo7.svg'
import img8 from '../../assests/offer_solutions/sec3-logo8.svg'

const S3OfferSolutions = () => {
    const S3cardData = [
        {
          "id": 1,
          image: img1,
          title: 'Mobile App Development',
          description: 'Now lead your business domain by choosing the worlds leading platform, Innovative and feature-rich Mobile App Development delivering your dreams.',
        },
        {
          "id": 2,
          image: img2,
          title: 'Custom Software Development',
          description: 'Expert Custom Software Development Solutions for start-ups to established businesses - your helping hand in each stage of your business cycle.',
        },
        {
          "id": 3,
          image: img3,
          title: 'IoT Application Development',
          description: 'Get ready to add Smart as your default prefix to call inanimate things around with high-end IoT App development that smartens your world up.',
        },
        {
          "id": 4,
          image: img4,
          title: 'Search Engine Optimization',
          description: 'Were committed to providing our customers with the best parcel sending experience in the market, at unrivalled prices.',
        },
        {
          "id": 5,
          image: img5,
          title: 'Quality Assurance',
          description: 'Ensure quality of any software product by running automatic and manual tests on it with our adept quality assurance team.',
        },
        {
          "id": 6,
          image: img6,
          title: 'Website Development',
          description: 'Think Unique, get unique. High-end Custom Web Development Services by world-leading IT Solution provider company.',
        },
        {
          "id": 7,
          image: img7,
          title: 'CRM Development',
          description: 'With our high-end CRM development Solutions, change the way your enterprise interacts with customers meanwhile improving sales productivity.',
        },
        {
          "id": 8,
          image: img8,
          title: 'POS Development',
          description: 'Advance POS Development Company, giving you control of your enterprise for better workflow, customer tracking, inventory management and much more.',
        },
      ];
  return (
             <Container fluid id='S3OfferSolutions'>
               <Container className='p-4'>
               <h1 className='text-dark text-center py-5' style={{fontWeight: '600'}}>We offer solutions that fit our client's needs.</h1>
                <Row className='g-4'>
                      {S3cardData.map((card, index)=>{
                        return(
                            <Col key={index} md={12} lg={6} className="d-flex justify-content-center">
                               <S3Cards
                                image={card.image}
                                title={card.title}
                                description={card.description}
                               />
                            </Col>
                        )
                      })}
                </Row>
               </Container>
             </Container>
  )
}

export default S3OfferSolutions
