import React, { useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Slider from 'react-slick';
import ClientReviewsChild from './ClientReviewsChild';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import img1 from '../../assests/testimonial/img1.jpg';
// import next from '../../assests/testimonial/next.webp'
// import prev from '../../assests/testimonial/previous.webp'
import './ClientReviewsSlider.css'

const settings = {
    dots: false,
    infinite: true, // Global setting
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          infinite: true, // Ensure it's enabled for this breakpoint
        },
      },
    ],
  };
    

const ClientReviewsSlider = () => {
    const arrowRef = useRef(null)
    const ClientSliderData = [
        {
            "id": 1,
            reviews: 'Working with SiredBits was a game changer for our business. Their team understood our vision and delivered a seamless, high-performing mobile app that exceeded our expectations.',
            name: "Willam James",
            companyName: "Devfinn",
            img: null,
            title: "Business Manager",
            rating: 5,
        },
        {
            "id": 2,
            reviews: "We faced challenges with scalability until we partnered with SiredBits. Their full-stack development expertise delivered a system that performs flawlessly, even under high traffic. We are incredibly grateful!.",
            name: "Michaell Smith",
            companyName: "SolzX",
            img: null,
            title: "Mobile Apps Developer",
            rating: 4,
        },
        {
            "id": 3,
            reviews: "Their creative and data-driven approach to digital strategy helped us engage with our audience in new and meaningful ways. SiredBits is not just a service provider; they are a true partner committed to our growth. testimonial from our client about the excellent work we've done. Highly recommended!",
            name: "Sarah Williams",
            companyName: "TechSolutions",
            img: null,
            title: "Team Lead",
            rating: 5,
        },
    ];


    return (
        <Container fluid>
            <Container className="my-4">
                <Row>
                    <Col className="text-center">
                        <h1 className="fs-1 fw-bolder">See what our clients say about us</h1>
                        <p className="client-subHeading pb-2">Here's what our long-term partners have to say about working with SiredBits Technologies.</p>

                        <div className="slider-wrapper d-flex justify-content-center position-relative">
                            <Slider ref={arrowRef} {...settings} className="slider-parent">
                                {ClientSliderData.map((review) => (
                                    <div key={review.id}>
                                        <ClientReviewsChild review={review} />
                                    </div>
                                ))}
                            </Slider>
                            <div className="slider-buttons">
                                {/* <button onClick={()=> arrowRef.current.slickPrev()} className='prev-btn' ><img src={prev} alt="prev-btn" /></button> */}
                                {/* <button  onClick={()=> arrowRef.current.slickNext()}   className='next-btn'> <img src={next} alt="next-btn" /> </button> */}
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default ClientReviewsSlider;
