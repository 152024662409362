import React from 'react'
import './WeAreSigi.css'
import imgR from '../../assests/aboutus/img.webp'

const WeAreSigi = () => {
  return (
    <section id='WeAreSigi' className='pt-4'>
       <div className="container-fluid WeAreSigi-parent mt-5">
        <div className="row text-white pb-5">
             <div className="col-sm-12 col-md-6 p-5 WeAreSigi-outer">
                <h1 className='fw-bolder pb-3'>We are SiredBits</h1>
                <p className='fs-5'>Our mission is to empower businesses with cutting-edge technology, enabling them to achieve sustainable growth and success.</p>
                <p className='fs-5'>SiredBits builds software that makes businesses stronger. We help you automate tasks, innovate processes, and create impactful digital platforms that drive efficiency and reduce costs.  Our team of expert developers and AI specialists will work closely with you to understand your needs and deliver custom solutions that give you a competitive edge.</p>
                <p className='fs-5'>We leverage the power of AI to optimize your operations, from streamlining workflows to enhancing customer experiences. Whether you need a robust web application, a mobile-first solution, or an AI-powered system to improve decision-making, we have the expertise and experience to bring your vision to life.</p>
                <p className='fs-5 fw-bolder'>Let's transform your business with technology that delivers real results.</p>
             </div>
             <div className="col-sm-12 col-md-6 p-0">
                <img src={imgR} alt="not found" className='img-fluid' />
             </div>
        </div>
       </div>

    </section>
  )
}

export default WeAreSigi
