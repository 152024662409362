import React from 'react'
import './ProductS4.css'
import img1 from '../../assests/product_section/img1.webp'
import img2 from '../../assests/product_section/img2.webp'
import img3 from '../../assests/product_section/img3.webp'
import img4 from '../../assests/product_section/img4.webp'
import img5 from '../../assests/product_section/img5.webp'
import img6 from '../../assests/product_section/img6.webp'
import logo1 from '../../assests/product_section/logo1.webp'
import logo2 from '../../assests/product_section/logo2.webp'
import logo3 from '../../assests/product_section/logo3.webp'
import logo4 from '../../assests/product_section/logo4.webp'
import logo5 from '../../assests/product_section/logo5.webp'
import logo6 from '../../assests/product_section/logo6.webp'
import bg1 from '../../assests/product_section/bg1.webp'
import bg2 from '../../assests/product_section/bg_two.png'
import bg3 from '../../assests/product_section/bg_three.png'

import './ProductCards'
import { Col, Container, Row } from 'react-bootstrap'
import ProductCards from './ProductCards'

const S4Products = () => {

  const productData = [
    {
      "id": 1,
      "logo": logo1,
      "heading": "Airband",
      "para": "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop ",
      "productImg": img1,
      "bgImage": bg1,
      "ImgWidth": "480px",
      "logoWidth":"160px",
    },
    {
      "id": 2,
      "logo": logo2,
      "heading": "Labeeh",
      "para": "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop ",
      "productImg": img2,
      "bgImage": bg2,
      "ImgWidth": "500px",
      "logoWidth":"70px",
    },
    {
      "id": 3,
      "logo": logo3,
      "heading": "Elite Gaming",
      "para": "It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop ",
      "productImg": img3,
      "bgImage": bg3,
      "ImgWidth": "480px",
      "logoWidth":"70px"
    },
    
  ]
  return (
    <div id='S4Products'>
     <Container fluid className="d-flex flex-column align-items-center">
      <Container className="my-5 w-75">
        <h1 className="text-dark fs-2 text-center fw-bolder pb-2">
          We love transforming product ideas to digital realities.
        </h1>
        <Row>
          {productData.map((card, index) => (
            <ProductCards {...card} key={index} />
          ))}
        </Row>
      </Container>
    </Container>
    </div>
  )
}

export default S4Products
