import React from 'react';

const ClientReviewsChild = ({ review }) => {
  return (

    <div className="testimonial-content-wrapper position-relative mb-4 mx-2">
    {/* <i class="fa-solid fa-quote-left"></i> */}
    <div className="review-content d-flex flex-column justify-content-center align-items-center">
      {/* <img
        src={review.img}
        alt="client-avatar"
        className="rounded-circle shadow-1-strong mb-4"
        style={{ width: '150px' }}
      /> */}
      <p className="text-muted px-1 mt-2 fw-bolder" style={{maxWidth:"820px"}}>
        {review.reviews}
      </p>
      <h5 className="mb-1 fw-bolder">{review.name}</h5>
        <p className="text-warning">
        {'★'.repeat(review.rating)}
      </p>
      <p style={{ fontSize: '14px' }}>{review.title}</p>
      {/* <i class="fa-solid fa-quote-right"></i> */}
    </div>
    </div>
   
  );
};

export default ClientReviewsChild;
