import React from "react";
import "./Footer.css";
import { Link } from "react-scroll";
import logo from "../../assests/logo/logo.png";

const Footer = () => {
  return (
    <section className="footer-parent py-3 pb-0">
      <div className="container">
        <div className="row py-3 mx-sm-3 mx-0">
          <div className="col-lg-3 col-md-12 col-sm-6 col-12 order-sm-4 order-lg-0 text-md-center text-lg-start mt-0 mt-sm-4 mt-lg-0 ">
            <Link to="AppHome" smooth={true} duration={500}>
              <img
                src={logo}
                alt="logo"
                className="img-fluid footer-logo mb-3"
                style={{ cursor: "pointer" }}
              />
            </Link>
            {/* <p>Lahore, Pakistan</p> */}
            <p>contact@siredbits.com</p>
            <p>+92 341 3700645</p>
            <div className="footer-icons-outer text-md-center mb-3">
              <i className="ri-facebook-circle-fill"></i>
              <i className="ri-twitter-line"></i>
              <i className="ri-instagram-line"></i>
              <a
                href="https://www.linkedin.com/company/siredbits/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                <i className="ri-linkedin-box-fill"></i>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-3">
            <h4 className="fw-bolder">Our Services</h4>
            <p className="pt-3">Website Development</p>
            <p>Mobile App Development</p>
            <p>On-Demand Apps</p>
            <p>Quality Assurance</p>
            <p>CRM Development</p>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-3">
            <h4 className="fw-bolder">Our Company</h4>
            <p className="pt-3">About</p>
            <p>Case Studies</p>
            <p>Careers</p>
            <p>Contact Us</p>
            <p>CRM Development</p>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-6 col-12 mt-3">
            <h4 className="fw-bolder">Industries</h4>
            <p className="pt-3">eCommerce</p>
            <p>Fintech</p>
            <p>Banking</p>
            <p>Healthcare</p>
            <p>Entertainment</p>
          </div>
          <div className="col-12 order-sm-4 order-lg-0 copyright ">
            <p className="text-center mt-3 mb-0">
              Copyright © 2024 SiredBits Technologies. All Rights Reserved
              <span className="mx-2">|</span>
              <a href="/terms-of-use">
                Terms of Use
              </a>
              <span className="mx-2">|</span>
              <a href="/privacy-policy">
                Privacy and Policy
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
