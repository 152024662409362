import React, { useState } from "react";
import "./TechStacksChild";
import TechStacksChild from "./TechStacksChild";

const TechStacksParent = () => {
  const [selectedCategory, setSelectedCategory] = useState("technologies");

  const handleCategories = (category) => {
    setSelectedCategory(category);
  };

  return (
    <section className="Tech-Stack-parent">
    <div className="container py-5 text-white">
      <h1 className="text-center mb-5 fw-bolder">
        Choose the tech stack for your next business idea
      </h1>
      <div className="row justify-content-center mb-4 mx-2"> 
        <div className="col-md-6 col-lg-2 text-center mb-3 mb-md-0 p-0">
          <button
                     className={`btn btn-primary btn-block w-100 ${selectedCategory === "technologies" ? "bg-yellow" : ""}`}
            onClick={() => handleCategories("technologies")}
          >
            Technologies
          </button>
        </div>
        <div className="col-md-6 col-lg-2 text-center mb-3 mb-md-0 p-0">
          <button
            className={`btn btn-primary btn-block w-100 ${selectedCategory === "frameworks" ? "bg-yellow" : ""}`}
            onClick={() => handleCategories("frameworks")}
          >
            Frameworks
          </button>
        </div>
        <div className="col-md-6 col-lg-2 text-center mb-3 mb-md-0 p-0">
          <button
           className={`btn btn-primary btn-block w-100 ${selectedCategory === "techAreas" ? "bg-yellow" : ""}`}
            onClick={() => handleCategories("techAreas")}
          >
            Business Areas
          </button>
        </div>
        <div className="col-md-6 col-lg-2 text-center mb-3 mb-md-0 p-0">
          <button
                 className={`btn btn-primary btn-block w-100 ${selectedCategory === "ourExperts" ? "bg-yellow" : ""}`}
            onClick={() => handleCategories("ourExperts")}
          >
            Our Experts
          </button>
        </div>
      </div>
      <TechStacksChild selectedCategory={selectedCategory} />
    </div>
  </section>
  
  );
};

export default TechStacksParent;
