import React from "react";
import logo from "../../assests/logo/logo.png";
import "./navbar.css";
import { Link } from "react-scroll";

import { Container, Navbar, Nav, Button } from "react-bootstrap";

const AppNavbar = () => {
  return (
    <Navbar expand="lg" className="nav-parent">
      <Container className="py-4">
        <Navbar.Brand>
          <Link>
            <img
              src={logo}
              alt="Logo"
              style={{ height: "60px", cursor: "pointer" }}
              className="img-fluid"
              onClick={() => window.location.reload()}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto navList-outer text-center text-lg-start d-flex flex-column flex-lg-row justify-content-center gap-2 gap-lg-5">
            <Link
              to="AppHome "
              smooth={true}
              duration={500}
              className="nav-link"
              onClick={() => window.location.reload()}
            >
              Home
            </Link>
            <Link
              to="WeAreSigi"
              smooth={true}
              duration={200}
              className="nav-link"
            >
              About
            </Link>
            <Link
              to="S3OfferSolutions"
              smooth={true}
              duration={500}
              className="nav-link"
            >
              Services
            </Link>
            <Link
              to="S4Products"
              smooth={true}
              duration={500}
              className="nav-link me-0 me-lg-5"
            >
              Products
            </Link>
          </Nav>
          <div className="d-flex justify-content-center justify-content-lg-end mt-3 mt-lg-0">
            <Link to="ContactUs" smooth={true} duration={500}>
              <Button variant="primary" href="#signup" className="nav-btn">
                Let's Talk
              </Button>
            </Link>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppNavbar;
