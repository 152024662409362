import React from 'react'
import './developmentS2.css'
import CardComponent from './S2DevelopCards';
import img1 from '../../assests/sec_developemnt/sec2-logo1.svg'
import img2 from '../../assests/sec_developemnt/sec2-logo2.svg'
import img3 from '../../assests/sec_developemnt/sec2-logo3.svg'
import img4 from '../../assests/sec_developemnt/sec2-logo1 (4).svg'
import { Container, Row, Col } from 'react-bootstrap';

const S2development = () => {

  const S2cardData = [
    {
      image: img1,
      title: 'Discover',
      description: 'We shape brands through exploration, applying in-depth research to challenge assumptions at every turn',
    },
    {
      image: img2,
      title: 'Desgin',
      description: 'Our design approach is to simplify. We embrace the joy of creating something unique that is easy for end-users.',
    },
    {
      image: img3,
      title: 'Built',
      description: 'Using modern technologies, we build with efficiency and skill, creating flexible and scalable business-driven solutions.',
    },
    {
      image: img4,
      title: 'Deliver',
      description: 'We take an iterative approach to both our work and our practice, always looking for ways to improve what we do.',
    },
  ];

  return (
   
 <Container fluid className="pt-5 bg-custom-orange">
      <Container className=" text-white py-5">
        <h1 className='text-center'>We make software development easy.</h1>
        <Row className="s2Development-row"> 
          {S2cardData.map((card, index) => (
            <Col key={index} md={6} lg={3} className=" S2-card-outer d-flex justify-content-center">
              <CardComponent 
                image={card.image}
                title={card.title}
                description={card.description}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Container>
  )
}

export default S2development
